import { type Options } from "react-to-pdf";
import { type IDropdownOption } from "@fluentui/react";
import { MutableRefObject, createContext, useContext } from "react";

import { MenuItemType } from "./api";

interface MenuContextValue {
    selectedMenuItem: string;
    setSelectedMenuItem: React.Dispatch<React.SetStateAction<string>>;
    toPDF: (options?: Options) => void;
    targetRef: MutableRefObject<any>;
    isAnswers: boolean;
    setIsAnswers: React.Dispatch<React.SetStateAction<boolean>>;
    isContinuing: boolean;
    setIsContinuing: React.Dispatch<React.SetStateAction<boolean>>;
    menuItems: MenuItemType[];
    setMenuItems: React.Dispatch<React.SetStateAction<MenuItemType[]>>;
    user: string;
    setUser: React.Dispatch<React.SetStateAction<string>>;
    formattedUserMenus: IDropdownOption<any>[];
    isMenuLoading: boolean;
    // isAuthorizedUser: boolean;
    authorizedPath: string;
}
// MenuContext is a context that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export const MenuContext = createContext<MenuContextValue | null>(null);

// useMenuContext is a custom hook that returns the MenuContext value.
export const useMenuContext = () => useContext(MenuContext);
