import { Link } from "react-router-dom";

import gea_logo_white from "../../assets/componentUses/gea_logo_white.png";
import mind_logo_white from "../../assets/componentUses/mind_logo_white.png";
import styles from "./Loading.module.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoadingProps {}

const AfterLoginLoading: React.FC<LoadingProps> = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="https://geacloud.sharepoint.com/sites/gea-connect" target="_blank" className={styles.headerTitleContainer}>
                        <img src={gea_logo_white} className="headerLogo" height="50px" />
                    </Link>
                    <div className={styles.projectName}>
                        <Link to={`/chat}`}>
                            <img src={mind_logo_white} style={{ marginRight: "10px" }} height="50px" />
                        </Link>
                        BetterBot AI
                    </div>
                </div>
            </header>
            <div className={styles.rightContainer}>
                <div className={styles.layout}>
                    <div className={styles.bgText}>
                        <span className={styles.loader} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AfterLoginLoading;
