import styles from "./Loading.module.css";
import gea_logo_white from "../../assets/componentUses/gea_logo_white.png";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
    return (
        <>
            <div className={styles.bgImage}></div>
            <img src={gea_logo_white} width={220} className={`${styles.gealogo} `} />
            <div className={styles.bgText}>
                <span className={styles.loader} />
            </div>
        </>
    );
};

export default Loading;
