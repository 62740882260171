import { createContext, useContext } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface RagasContextValue {
    selectedUseCase: { key: string | number; text: string };
    setSelectedUseCase: React.Dispatch<React.SetStateAction<{ key: string | number; text: string }>>;
    useCaseOptions: { key: string | number; text: string };
    setUseCaseOptions: React.Dispatch<React.SetStateAction<{ key: string | number; text: string }[]>>;
    selectedMetrics: { key: string | number; text: string }[];
    setSelectedMetrics: React.Dispatch<React.SetStateAction<{ key: string | number; text: string }[]>>;
}

// RagasContext is a context that provides the selected menu item, a function to set the selected menu item, a function to generate a PDF, a reference to the target element, and a flag to indicate whether the answers are being displayed.
export const RagasContext = createContext<any | null>(null);

// useRagasContext is a custom hook that returns the RagasContext value.
export const useRagasContext = () => useContext(RagasContext);
